@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetic Now Display Black';
  src: url('https://elevationchurch.org/app/uploads/2020/11/HelveticaNowDispBlk.woff');
}

body {
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  color: #1E1E1E;
  background-color: #fff;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}
@layer base {
  #root {
    @apply min-h-screen flex flex-col;
  }
  a { text-decoration: none; }
  a.btn { display: block; }
  /* a:hover, a:active { opacity: 0.8; text-decoration: none; } */

  textarea { resize: none; }
  .font-primary {
    font-family: "Helvetic Now Display", sans-serif;
  }
  .font-secondary {
    font-family: "Druk Bold", Sans-serif;
  }
}
@layer components {
  .btn {
    transition: transform .3s ease;
    @apply py-1 px-2 rounded;
  }
  .btn:hover:not(:disabled) { transform: scale(1.03) }
  .btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .btn-lg {
    @apply btn px-4 py-2;
  }
  .btn-fit {
    @apply w-full rounded-2xl shadow-sm p-4;
  }
  .btn-primary {
    @apply btn bg-primary text-white;
  }
  .btn-secondary {
    @apply btn bg-black text-white;
  }
  .card {
    @apply rounded-2xl bg-gray-100 p-4;
  }
  .orange-glow {
    box-shadow: 0px 4px 16px rgba(255, 103, 31, 0.24), 0px 8px 24px rgba(255, 122, 60, 0.16), 0px 16px 32px rgba(255, 103, 31, 0.08);
  }
}
